import { Button, Flex, Link, HStack, VStack } from '@chakra-ui/react'
import { forAdminOnly } from 'modules/auth'
import { useGoogleOAuthUser } from 'modules/googleOAuth/hooks'
import {
  UserAvatar as GammaUserAvatar,
  LoginOrLogoutButton as GammaLoginOrLogoutButton,
} from 'modules/googleOAuth/components'
import React from 'react'
import { FaGoogle } from 'react-icons/fa'
import { UserAvatar, LoginOrLogoutButton } from 'modules/auth'

import asClientPage from '../layout/ClientPage'

function HomePage() {
  const { user, isReady } = useGoogleOAuthUser()
  // We should get this from the JWT
  const isGammaAdmin = user && user.email && user.email.endsWith('@gamma.app')

  console.log({ gammaUser: user, gammaUserReady: isReady })

  return (
    <Flex
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Flex
        padding={4}
        justifyContent="center"
        alignItems="center"
        alignSelf="flex-end"
        position="fixed"
        top={0}
      >
        <GammaUserAvatar />
        <Flex padding={2}></Flex>
        <GammaLoginOrLogoutButton />
      </Flex>
      {/* <h1>Gamma</h1> */}
      <h2>Coming Soon...</h2>

      {isGammaAdmin && (
        <Flex
          padding={10}
          bg="orange"
          borderRadius={10}
          alignItems="center"
          justifyContent="center"
        >
          <Link href="./admin">
            <Button colorScheme="blackAlpha">Admin Page</Button>
          </Link>
          <h2>Firebase Google OAuth</h2>

          <UserAvatar />
          <Flex padding={2}></Flex>
          <LoginOrLogoutButton />
        </Flex>
      )}
    </Flex>
  )
}

export default asClientPage(HomePage)
