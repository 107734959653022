import { Avatar, AvatarBadge, Button, Flex, Spinner } from '@chakra-ui/react'
import React, { useCallback } from 'react'

import { getUserColor } from 'modules/present/utils'
import { useDisplayName } from 'modules/present/utils'

import {
  useGoogleOAuthUser,
  useGoogleOAuthLogin,
  useGoogleOAuthLogout,
} from './hooks'

export const UserAvatar = () => {
  const { isReady, user } = useGoogleOAuthUser()
  const displayName = useDisplayName({
    uid: user?.id,
    displayName: `${user?.firstName} ${user?.lastName}`,
  })
  const color = user ? getUserColor(user?.id) : '#c6c6c6'
  if (user) {
    return (
      <Avatar
        name={(user && (displayName as string)) || ''}
        size="sm"
        border={`1px solid ${color}`}
        src={user.profileImageUrl}
        backgroundColor={`${color}AB`}
      >
        <AvatarBadge boxSize="1.1em" bg={'green.500'} />
      </Avatar>
    )
  }
  return (
    <Avatar
      name="?"
      bg="grey"
      size="sm"
      border={`1px solid ${color}`}
      backgroundColor={`${color}AB`}
    >
      <AvatarBadge borderColor="darkgrey" bg="grey" boxSize="1.1em" />
    </Avatar>
  )
}

export const LoginOrLogoutButton = () => {
  const login = useGoogleOAuthLogin()
  const logout = useGoogleOAuthLogout()
  const { user, isReady } = useGoogleOAuthUser()
  return user ? (
    <Button
      onClick={logout}
      colorScheme="red"
      variant="solid"
      size="xs"
      isLoading={!isReady}
    >
      Logout
    </Button>
  ) : (
    <Button
      onClick={login}
      colorScheme="green"
      variant="solid"
      size="xs"
      isLoading={!isReady}
    >
      Login
    </Button>
  )
}
